<template>
  <div>
    <div class="modal-backdrop fade show"></div>
    <div @click="closeModal" class="modal">
      <div @click.stop class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add new user</h5>
          </div>
          <div class="modal-body">
            <label for="username">Username</label>
            <input v-model="username" class="form-input" id="username" type="text" />
            <label for="password">Password</label>
            <input v-model="password" class="form-input" id="password" type="password" />
            <label for="description">Description</label>
            <input v-model="description" class="form-input" id="description" type="text" />
          </div>
          <div class="modal-footer">
            <button @click="closeModal" class="btn">Cancel</button>
            <button @click="addUser" :disabled="disableSave()" class="btn">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../store/index'

export default {
  name: 'AddUserModal',
  store,
  data () {
    return {
      username: '',
      password: '',
      description: ''
    }
  },
  methods: {
    closeModal () {
      this.$emit('close-modal')
    },
    async addUser () {
      const userData = this.$data
      await this.$store
        .dispatch('ftp/createFtpUser', userData)
        .then(res => {
          let user = res.data
          this.$emit('ftp-user-added', user)
        })
        .catch(err => console.log(err))
      this.closeModal()
    },
    disableSave () {
      return !this.username || !this.password || !this.description
    }
  }
}
</script>

<style lang='scss'>
@import "../../styles/modal.scss";
</style>
