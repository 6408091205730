<template>
  <div class="dropdown-loader"></div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.dropdown-loader {
  border: 0.2em solid transparent;
  border-top-color: #007bff;
  border-radius: 50%;
  -webkit-animation: 1s loader linear infinite;
  animation: 1s loader linear infinite;
  position: sticky;
  position: -webkit-sticky;
  top: 50%;
  left: 43%;
  z-index: 1000;

  display: inline-block;
  width: 2em;
  height: 2em;
  color: inherit;
  vertical-align: middle;
  pointer-events: none;
  &:before {
    content: "";
    display: block;
    width: inherit;
    height: inherit;
    position: absolute;
    top: -0.2em;
    left: -0.2em;
    border: 0.2em solid #007bff;
    border-radius: 50%;
    opacity: 0.5;
    box-sizing: inherit;
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
