import Vue from 'vue'
import Vuex from 'vuex'

import ftp from './modules/ftp'

import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    components: {
      dialog: {
        name: '',
        isOpen: false,
        data: {}
      },
      notification: {
        queue: []
      },
      loader: {
        isOpen: false
      }
    }
  },
  getters: {},
  mutations,
  actions,
  modules: {
    ftp
  }
})
