<template>
  <div class="parent-wrapper">
    <auto-complete-dropdown :id="id" :system="system"></auto-complete-dropdown>
    <add-user @ftp-user-added="userAdded"></add-user>
  </div>
</template>

<script>
import AutoCompleteDropdown from './AutoCompleteDropdown.vue'
import AddUser from './AddUser.vue'

export default {
  data () {
    return {
      user: {}
    }
  },
  props: ['id', 'system'],
  components: {
    AutoCompleteDropdown,
    AddUser
  },
  methods: {
    userAdded (data) {
      this.user = data
    }
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}
.parent-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
