import axios from '../../server'
import store from '../index'

const state = {
  users: [],
  totalItems: 0,
  totalPages: 0,
  mappedUser: {}
}

const showNotify = ({ message, isSuccess }) => {
  store.dispatch('displayNotification', {
    id: Math.floor(Math.random() * 1000),
    isSuccess,
    message
  })
}

const toggleLoader = (showLoader) => {
  store.commit('toggleLoader', showLoader)
}

const actions = {
  getFtpUsers ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      toggleLoader(true)
      let { page = 1, pageSize = 30, query = '' } = payload
      axios
        .get(`ftpusers?page=${page}&page_size=${pageSize}&q=${query}`)
        .then(response => {
          commit('storeFtpUsers', response.data)
          resolve(response)
        })
        .catch(err => {
          showNotify({ message: 'Failed to fetch ftp users', isSuccess: false })
          console.log(err)
          reject(err)
        }).finally(() => { toggleLoader(false) })
    })
  },
  getSystemMapping ({ commit }, { system, id }) {
    axios
      .get(`mappings/${system}/${id}`)
      .then(res => {
        commit('storeMappedUser', res.data.ftp_account)
        document.cookie = 'hasMapping=1'
      })
      .catch(err => {
        document.cookie = 'hasMapping=0'
        console.log(err)
      })
  },
  getPaginatedUsers ({ commit }, { page = 1, pagesize = 30, query = '' }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`ftpusers?q=${query}&page=${page}&page_size=${pagesize}`)
        .then(res => {
          commit('appendFtpUsers', res.data)
          resolve(res)
        })
        .catch(err => {
          console.log(err)
          reject(err)
        })
    })
  },
  setSystemMapping ({ commit }, { system, ...body }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`mappings/${system}`, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          console.log(err)
          reject(err)
        })
    })
  },
  deleteSystemMapping ({ commit }, { system, id }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`mappings/${system}/${id}`)
        .then(res => {
          resolve(res)
          // console.log('deleteSystemMapping done')
        })
        .catch(err => {
          console.log('deleteSystemMapping error: ' + err)
          reject(err)
        })
    })
  },
  editFtpUser ({ commit, state }, { id, ...payload }) {
    toggleLoader(true)
    axios
      .put(`ftpusers/${id}`, payload)
      .then(res => {
        const editedUsers = {
          ftpusers: state.users.map(user => {
            if (user.id === id) {
              return res.data
            }
            return user
          }),
          total_items: state.totalItems,
          total_pages: state.totalPages
        }
        commit('storeFtpUsers', editedUsers)
        showNotify({ message: 'Ftp user edited successfully', isSuccess: true })
      })
      .catch(err => {
        showNotify({ message: 'Failed to edit ftp user', isSuccess: false })
        console.log(err)
      }).finally(() => { toggleLoader(false) })
  },
  createFtpUser ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      toggleLoader(true)
      axios
        .post('ftpusers', payload)
        .then(res => {
          let updatedUsers = {
            ftpusers: [...state.users, res.data],
            total_items: state.totalItems + 1,
            total_pages: state.totalPages
          }
          commit('storeFtpUsers', updatedUsers)
          resolve(res)
          showNotify({ message: 'New ftp user added successfully', isSuccess: true })
        })
        .catch(err => {
          showNotify({ message: 'Failed to add new ftp user', isSuccess: false })
          console.log(err)
          reject(err)
        }).finally(() => { toggleLoader(false) })
    })
  },
  deleteFtpUser ({ commit, state }, id) {
    toggleLoader(true)
    axios
      .delete(`ftpusers/${id}`)
      .then(res => {
        let filteredUsers = {
          ftpusers: state.users.filter(user => {
            return user.id !== id
          }),
          total_items: state.totalItems - 1,
          total_pages: state.totalPages
        }
        commit('storeFtpUsers', filteredUsers)
        showNotify({ message: 'Ftp user deleted successfully', isSuccess: true })
      })
      .catch(err => {
        showNotify({ message: 'Failed to delete ftp user', isSuccess: false })
        console.log(err)
      }).finally(() => { toggleLoader(false) })
  },
  changePassword (_, { password, id }) {
    toggleLoader(true)
    axios.patch(`ftpusers/${id}`, {
      password
    }).then(() => {
      showNotify({ message: 'Password changed successfully', isSuccess: true })
    }).catch(() => {
      showNotify({ message: 'Failed to change password', isSuccess: false })
    }).finally(() => {
      toggleLoader(false)
    })
  }
}

const mutations = {
  storeFtpUsers (state, payload) {
    let {
      ftpusers = [],
      total_items: totalItems = 1,
      total_pages: totalPages = 1
    } = payload
    state.users = [...ftpusers]
    if (totalItems && totalPages) {
      state.totalItems = totalItems
      state.totalPages = totalPages
    }
  },
  appendFtpUsers (state, payload) {
    let {
      ftpusers = [],
      total_items: totalItems = 1,
      total_pages: totalPages = 1
    } = payload
    state.users = [...state.users, ...ftpusers]
    if (totalItems && totalPages) {
      state.totalItems = totalItems
      state.totalPages = totalPages
    }
  },
  storeMappedUser (state, payload) {
    state.mappedUser = payload
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
