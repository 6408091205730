export const changeDialogState = ({ commit }, payload) => {
  commit('changeDialogState', payload)
}

export const displayNotification = ({ commit, state }, payload) => {
  commit('addNotification', payload)
  setTimeout(() => {
    let notifyPresent = state.components.notification.queue.some(
      notify => notify.id === payload.id
    )
    if (notifyPresent) { commit('removeNotification', payload.id) }
  }, 3000)
}
