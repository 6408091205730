export const changeDialogState = (state, payload) => {
  state.components.dialog = {
    ...payload
  }
}

export const addNotification = (state, payload) => {
  state.components.notification.queue = [...state.components.notification.queue, payload]
}

export const removeNotification = (state, index) => {
  state.components.notification.queue = state.components.notification.queue.filter(x => {
    return x.id !== index
  })
}

export const toggleLoader = (state, showLoader) => {
  state.components.loader.isOpen = showLoader
}
