<template>
  <div class="q-notifications__list q-notifications__list--bottom fixed column items-center">
    <div
      :key="notification.id"
      v-for="(notification) in notifications"
      class="q-notification row items-center text-white q-notification--standard"
      :class="notification.isSuccess ? 'bg-positive' : 'bg-negative'"
    >
      <div class="row items-center col">
        <div class="q-notification__message col">{{notification.message}}</div>
      </div>
      <div class="q-notification__actions row items-center col-auto">
        <button
          @click="removeNotification(notification.id)"
          tabindex="0"
          type="button"
          class="q-btn q-btn-item non-selectable no-outline q-btn--flat q-btn--rectangle text-white q-btn--actionable q-focusable q-hoverable q-btn--wrap"
        >
          <div tabindex="-1" class="q-focus-helper"></div>
          <div class="q-btn__wrapper col row q-anchor--skip">
            <div
              class="q-btn__content text-center col items-center q-anchor--skip justify-center row"
            >
              <app-icon name="cancel" size="medium"></app-icon>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../store/index'
import Icon from '../shared/Icon.vue'

export default {
  store,
  computed: {
    notifications () {
      return this.$store.state.components.notification.queue
    }
  },
  components: {
    appIcon: Icon
  },
  methods: {
    removeNotification (index) {
      this.$store.commit('removeNotification', index)
    }
  }
}
</script>

<style lang="scss">
.bg-positive {
    background: #21ba45 !important;
}

.bg-negative {
    background: #c10015 !important;
}
</style>
