<template>
  <div class="dropdown">
    <button
      class="select-user-button"
      :class="{'not-selected': !currentUser.username, 'open': open, 'success': mapStatus === 'success', 'failed': mapStatus === 'failed'}"
      @click="toggleDropdown(!open)"
      id="dropdown"
    >
      <div
        class="username"
      >{{currentUser.username ? `${currentUser.username} - ${currentUser.description}` : 'Select account'}}</div>
      <i class="arrow" :class="{'rotated': open}"></i>
    </button>
    <span
      v-if="mapStatus === 'success' || mapStatus === 'failed'"
      :class="{'mapSuccess': mapStatus === 'success', 'mapFailed': mapStatus === 'failed'}"
    >{{ mapStatus === 'success' ? "Ftp account changed successfully" : "Failed to change ftp account" }}</span>
    <ul @scroll="dropdownScroll" class="users-list" :class="{'open': open}">
      <li class="list-item sticky loader" v-if="mapStatus === 'inProgress'">
        <dropdown-loader></dropdown-loader>
      </li>
      <li class="list-item sticky">
        <input
          ref="search"
          class="search"
          type="text"
          @keydown.esc="toggleDropdown(false)"
          @blur="toggleDropdown(false)"
          placeholder="Search account"
          v-model="searchText"
          @input="searchUser"
        />
      </li>
      <li
        class="list-item"
        :class="{'selected': currentUser.id === user.id}"
        @mousedown.prevent
        :key="index"
        v-for="(user, index) in filteredUsers"
        @click="userSelected(user)"
      >
        <div class="username">{{ user.username + ' - ' + user.description }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import store from '../../store/index'
import DropdownLoader from '../loader/DropdownLoader.vue'
import debounce from 'lodash/debounce'

export default {
  name: 'AutoCompleteDropdown',
  store,
  data () {
    return {
      searchText: '',
      open: false,
      currentUser: {},
      attributes: { id: '', system: '' },
      mapStatus: '',
      responseMessage: '',
      currentPage: 1
    }
  },
  props: ['user', 'id', 'system'],
  components: {
    DropdownLoader
  },
  created () {
    this.$store.dispatch('ftp/getPaginatedUsers', {}).then(res => {
      this.currentPage += 1
    })
    this.$store.dispatch('ftp/getSystemMapping', {
      id: this.id,
      system: this.system
    })
    this.attributes = { id: this.id, system: this.system }
  },
  methods: {
    toggleDropdown (isOpen) {
      this.open = isOpen
      if (isOpen) {
        this.$nextTick(() => {
          this.$refs.search.focus()
          let selectedAccount = this.$el.querySelector('.selected')
          if (selectedAccount) {
            selectedAccount.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }
        })
      }
    },
    userSelected (user) {
      if (JSON.stringify(user) !== JSON.stringify(this.currentUser)) {
        // If the mapping already exists, the system gets a duplicate key error on the backend when posting.
        // There is no PUT for mappings so we need to do a DELETE followed by the normal POST.
        // Before deleting, check that the mapping exists (prepare the flag in getSystemMapping(), src/store/modules/ftp.js)
        if (document.cookie.indexOf('hasMapping=1') >= 0) {
          this.$store.dispatch('ftp/deleteSystemMapping', {
            id: this.attributes.id,
            system: this.attributes.system
          }).then(() => {
            this.setMapping(user, 0) // posted after deletion
          })
        } else {
          this.setMapping(user, 0) // posted without deletion
        }
      }
      document.cookie = 'hasMapping=1'
      this.$store.commit('ftp/storeFtpUsers', { ftpusers: [] })
      this.$store.dispatch('ftp/getPaginatedUsers', {})
      this.currentUser = user
      this.currentPage = 2
      this.open = false
      this.searchText = ''
      this.$emit('ftp-dropdown-value', user)
      this.$refs.search.blur()
    },
    setMapping (user, testFlag) {
      this.$store
        .dispatch('ftp/setSystemMapping', {
          system: this.attributes.system,
          id: this.attributes.id,
          ftp_id: user.id
        })
        .then(res => {
          this.mapStatus = 'success'
        })
        .catch(err => {
          console.log('err is ', err)
          this.mapStatus = 'failed'
        })
        .finally(() => {
          setTimeout(() => {
            this.mapStatus = ''
          }, 2000)
        })
      if (testFlag > 0) {
        const testMsg = (testFlag === 1) ? 'Posted after deletion' : 'Posted without deletion'
        console.log(testMsg)
      }
    },
    dropdownScroll: debounce(function () {
      var dropdown = this.$el.querySelector('.users-list')
      if (
        dropdown.scrollHeight - dropdown.scrollTop === dropdown.clientHeight &&
        this.currentPage <= this.totalPages
      ) {
        this.mapStatus = 'inProgress'
        this.$store
          .dispatch('ftp/getPaginatedUsers', {
            page: this.currentPage,
            pagesize: 30,
            query: this.searchText
          })
          .then(res => {
            this.mapStatus = ''
            this.currentPage += 1
          })
          .catch(err => console.log(err))
      }
    }, 100),
    searchUser: debounce(function (event) {
      this.currentPage = 1
      this.$store.commit('ftp/storeFtpUsers', { ftpusers: [] })
      this.dropdownScroll()
    }, 1000)
  },
  watch: {
    user: function (value) {
      this.ftpUsers.push(JSON.parse(value))
    },
    id: function (id) {
      this.attributes.id = id
    },
    system: function (system) {
      this.attributes.system = system
    },
    mappedUser: function (user) {
      this.currentUser = user
    }
  },
  computed: {
    ftpUsers () {
      return this.$store.state.ftp.users
    },
    totalPages () {
      return this.$store.state.ftp.totalPages
    },
    filteredUsers () {
      return this.ftpUsers.filter(user => {
        let userData = `${user.username} - ${user.description}`
        return userData
      })
    },
    mappedUser () {
      return this.$store.state.ftp.mappedUser
    }
  }
}
</script>
<style lang="scss">
* {
  box-sizing: border-box;
}

.dropdown {
  display: inline-block;

  .users-list {
    width: 250px;
    max-height: 500px;
    min-height: 200px;
    background-color: rgba(255, 255, 255, 0.95);
    border: 1px solid #ddd;
    list-style: none;
    display: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: absolute;
    z-index: 2;
    overflow-y: scroll;
    &.open {
      display: block;
    }
    .list-item {
      cursor: pointer;
      width: 250px;
      display: flex;
      align-items: center;
      outline: none;
      padding: 0.2rem 0.3rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      &.sticky {
        position: sticky;
        top: 0;
        z-index: 1;
      }
      &.loader {
        top: 50%;
        height: 0;
        background: transparent;
        padding: 0;
      }
      &:not(.sticky) {
        &:hover {
          color: #fff;
          background-color: #ccc;
        }
      }

      .search {
        width: 100%;
        outline: none;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        padding: 0.5rem;
      }

      .username {
        flex-grow: 1;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .actions {
        display: flex;
      }
      &.selected {
        background: #007bff;
        color: #fff;
        pointer-events: none;
      }
    }
  }

  .mapSuccess {
    position: absolute;
    padding: 0.2rem 0 0 0.5rem;
    color: #32cd32;
  }

  .mapFailed {
    position: absolute;
    padding: 0.2rem 0 0 0.5rem;
    color: #ff0800;
  }
}

.select-user-button {
  width: 250px;
  position: relative;
  padding: 3px 4px;
  min-height: 30px;
  align-items: center;
  border: 1px solid #ddd;
  font-size: 1em;
  outline: none;
  text-align: left;
  display: flex;
  justify-content: space-between;
  background-image: linear-gradient(#fff, #839bbd);
  &.open {
    pointer-events: none;
  }
  &.not-selected {
    opacity: 0.5;
  }
  &.success {
    border: 2px solid #32cd32;
  }
  &.failed {
    border: 2px solid #ff0800;
  }
  .username {
    width: calc(100% - 32px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #2f436a;
  }
  .arrow {
    border: solid #839bbd;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    position: absolute;
    transform: rotate(45deg);
    right: 10px;
    transition: all 100ms linear;
    &.rotated {
      transform: rotate(225deg);
    }
    &::after {
      content: "";
      height: 25px;
      display: inline-block;
      width: 24px;
      position: absolute;
      transform: rotate(-45deg);
      right: -12px;
      top: -6px;
      bottom: 0;
    }
  }
}
</style>
