<template>
  <div class="addUserWrapper">
    <add-user-modal v-if="isModalOpen" @ftp-user-added="ftpUserAdded" @close-modal="closeModal"></add-user-modal>
    <app-notification v-if="showNotify"></app-notification>
    <button @click="openModal" class="add-btn">
      <app-icon name="add" size="large">/</app-icon>
    </button>
  </div>
</template>

<script>
import AddUserModal from '../modal/AddUserModal.vue'
import Icon from '../shared/Icon.vue'
import Notification from '../shared/Notification.vue'
import store from '../../store/index'

export default {
  name: 'AddUser',
  store,
  data () {
    return {
      isModalOpen: false
    }
  },
  components: {
    AddUserModal,
    appIcon: Icon,
    appNotification: Notification
  },
  computed: {
    showNotify () {
      return this.$store.state.components.notification.queue.length
    }
  },
  methods: {
    openModal () {
      this.isModalOpen = true
    },
    closeModal () {
      this.isModalOpen = false
    },
    ftpUserAdded (data) {
      this.$emit('ftp-user-added', data)
    }
  }
}
</script>

<style lang="scss">
@import '~quasar/dist/quasar.min.css';

.addUserWrapper {
  margin-left: 1rem;
  align-self: flex-end;
}

.add-btn {
  background-color: #007bff;
  outline: none;
  border: none;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
